<template>
  <div class="readme-article">
    <h2 id="设置">7.设置</h2>
    <h3 id="基本设置">7.1基本设置</h3>
    <p>
     预留模块，暂无作用，如要配置基本信息请前往名片->通用设置处配置
    </p>
    <p>
      <img src="@/assets/img/card/7-1.png" />
    </p>
    <h3 id="短信通知">7.2短信通知</h3>
    <p>
      预留模块，暂无提供短信功能，无需配置。
    </p>
    <!-- <p>
      <img src="@/assets/img/card/7-2.png" />
    </p> -->
    <h3 id="上传设置">7.3上传设置</h3>
    <p>
      配置文件上传条件，可限制上传文件类型，大小等信息。
    </p>
    <p>
      <img src="@/assets/img/card/7-3.png" />
    </p>
    <h3 id="其他">7.4其他</h3>
    <h4 id="清理缓存">7.4.1清理缓存</h4>
    <p>
       系统存在缓存机制，如遇数据长时间不更新，可在此处清理缓存。
    </p>
    <p>
      <img src="@/assets/img/card/7-4.png" />
    </p>
    <h3 id="小程序设置">7.5小程序设置</h3>
    <p>
      配置小程序基本信息，包括小程序名称、小程序id、小程序密钥、自定义底部图标以及底部字体颜色等信息配置。
    </p>
    <p>
      <img src="@/assets/img/card/7-5.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card7-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>